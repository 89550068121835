import { Box, Button, Heading } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = {}

export const FixedInternetButtons: React.FC<Props> = ({}) => {
  return (
    <Box marginTop="3rem" flex="1">
      <Heading as="h4" fontSize="1.5rem" margin="0.5rem 1rem">
        Etsitkö jotain muuta?
      </Heading>
      <Link to="/mobiililaajakaista">
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso mobiililaajakaistat
        </Button>
      </Link>
      <Link to="/mokkula">
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso mokkulat
        </Button>
      </Link>
    </Box>
  )
}
