import { Box, Button, Text } from "@chakra-ui/core"
import React from "react"
import { CardContainer } from "../../cards/CardContainer"
import OperatorImage from "../../images/OperatorImage"

type Props = { name: string; operator: string; link: string }

export const FixedInternetCard: React.FC<Props> = ({
  name,
  operator,
  link,
}) => {
  return (
    <CardContainer name={operator} slug="laajakaista" directLink={link}>
      <Text fontWeight="600">{name}</Text>
      <Box width="200px" marginY="1rem">
        <OperatorImage name={operator} />
      </Box>
      <Box marginY="2rem">
        <Button
          border="none"
          bg="action"
          color="white"
          fontSize="1.3rem"
          width="220px"
          height="50px"
          cursor="pointer"
        >
          Tarkista saatavuus
        </Button>
      </Box>
    </CardContainer>
  )
}
