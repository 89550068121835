import { Box, Heading } from "@chakra-ui/core"
import React from "react"
import { MobileInternetPlan } from "../../../types/MobileInternetPlan"
import { Heading3 } from "../../headings/Heading3"
import { RightContainer } from "../RightContainer"
import { MobileInternetCard } from "./MobileInternetCard"

type Props = {
  plans: MobileInternetPlan[]
  limited?: boolean
  compact?: boolean
  title?: string
  slug: string
}

export const MobileInternetContainer: React.FC<Props> = ({
  plans,
  limited,
  compact,
  title,
  slug,
}) => {
  const newPlans = limited ? plans.slice(0, 3) : plans
  const planCards = newPlans.map(plan => (
    <MobileInternetCard slug={slug} compact={compact} plan={plan} />
  ))
  return (
    <RightContainer>
      {title && <Heading3>{title}</Heading3>}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        marginTop="2rem"
      >
        {planCards}
      </Box>
    </RightContainer>
  )
}
