import { Box, Button, Heading, Link as ExternalLink } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = {}

export const MokkulaButtons: React.FC<Props> = ({}) => {
  return (
    <Box marginTop="3rem" flex="1">
      <Heading as="h4" fontSize="1.5rem" margin="0.5rem 1rem">
        Mitä tarvitset?
      </Heading>
      <ExternalLink
        target="_blank"
        rel="nofollow"
        href="https://track.adtraction.com/t/t?a=1298137859&as=1543031511&t=2&tk=1&epi=elisa-mokkulatkaikki&url=https://elisa.fi/kauppa/laitteet/tietotekniikka/nettilaitteet"
      >
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso kaikki mokkulat
        </Button>
      </ExternalLink>
      <Link to="/mobiililaajakaista/#result">
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso kaikki nettiliittymät
        </Button>
      </Link>
    </Box>
  )
}
