import { Box, Button, List, ListIcon, ListItem, Text } from "@chakra-ui/core"
import React from "react"
import { MobilePlan } from "../../../../types/MobilePlan"
import OperatorImage from "../../../images/OperatorImage"
import { CardContainer } from "../../CardContainer"
import { HighlighText } from "../cardItems/HighlighText"
import { PlanItem } from "../cardItems/PlanItem"

type Props = {
  plan: MobilePlan
  compact?: boolean | undefined
  slug: string
  showBenefits?: boolean
}

export const MobilePlanCard: React.FC<Props> = ({
  plan,
  compact,
  slug,
  showBenefits = true,
}) => {
  return (
    <CardContainer name={plan.name} slug={slug} compact={compact}>
      <HighlighText
        bg={plan.unlimited ? "info" : "#6B53FD"}
        text={plan.unlimited ? "Rajaton" : "Käytön mukaan"}
      />
      <Text marginTop="3rem" fontSize="1.1rem" textTransform="capitalize">
        {plan.operator}
      </Text>
      <Box width="200px" m="1rem auto">
        <OperatorImage name={plan.operator} />
      </Box>
      <Text fontWeight="600" fontSize="1.2rem">
        {plan.name}
      </Text>
      <PlanItem
        title="Puhelut ja viestit"
        value={plan.unlimited ? "rajaton" : `${plan.usePrice}€`}
      />
      <PlanItem
        title="Internetin max nopeus"
        value={`${plan.internetSpeed} Mbit/s`}
      />
      <PlanItem title="Netti EU-maissa" value={`${plan.roamingData} Gt/kk`} />
      <Box>
        {showBenefits && (
          <List
            spacing={3}
            textAlign="left"
            fontWeight="600"
            fontSize="0.9rem"
            marginX="1rem"
            textTransform="capitalize"
            minH="150px"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginTop="2rem"
          >
            {plan.benefits.map((b, i) => (
              <ListItem color="#34268C" key={i}>
                <ListIcon icon="check-circle" color="#34268C" />
                {b}
              </ListItem>
            ))}
          </List>
        )}
        <Text fontSize="2.2rem" fontWeight="800">
          {plan.salePrice && (
            <Text as="span" fontSize="0.8rem" color="info">
              {plan.saleDuration}kk ajan
            </Text>
          )}{" "}
          {plan.monthlyPrice}€
          <Text as="span" fontSize="1rem" fontWeight="300">
            /kk
          </Text>
          {plan.salePrice ? (
            <Text fontSize="0.8rem" fontWeight="300">
              {plan.saleDuration}kk jälkeen {plan.salePrice}€. Ei
              määräaikaisuutta.
            </Text>
          ) : (
            <Text fontSize="0.8rem" fontWeight="300">
              Voit irtisanoa liittymän millon haluat!
            </Text>
          )}
        </Text>
      </Box>

      <Button
        border="none"
        bg="action"
        color="white"
        fontSize="1.3rem"
        width="220px"
        height="50px"
        cursor="pointer"
      >
        Tutustu ja tilaa
      </Button>
      <Box fontWeight="700" marginTop="1rem">
        Avausmaksu {plan.openFee}€
      </Box>
      <Box fontWeight="400" fontSize="1.1rem">
        Ei määräaikaa
      </Box>
    </CardContainer>
  )
}
