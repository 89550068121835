import { Box } from "@chakra-ui/core"
import React from "react"
import { HuntingPlan } from "../../../types/HuntingPlan"
import { Heading3 } from "../../headings/Heading3"
import { RightContainer } from "../RightContainer"
import { HuntingCard } from "./HuntingCard"

type Props = {
  plans: HuntingPlan[]
  slug: string
}

export const HuntingDealsContainer: React.FC<Props> = ({ plans, slug }) => {
  const planCards = plans.map(plan => <HuntingCard plan={plan} slug={slug} />)

  return (
    <RightContainer>
      <Heading3>Suositut laitenetit</Heading3>

      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        marginTop="3rem"
      >
        {planCards}
      </Box>
    </RightContainer>
  )
}
