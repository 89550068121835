import { Box } from "@chakra-ui/core"
import React from "react"
import { Heading3 } from "../../headings/Heading3"
import { RightContainer } from "../RightContainer"
import { FixedInternetCard } from "./FixedInternetCard"

export const FixedInternetContainer = () => {
  return (
    <RightContainer>
      <Heading3>
        Katso kotiosoitteesi tarjonta seuraavilta operaattoreilta
      </Heading3>
      <Box display="flex" flexWrap="wrap">
        <FixedInternetCard
          link="https://track.adtraction.com/t/t?a=1298137859&as=1543031511&t=2&tk=1&epi=elisa-kiinte%C3%A4laajakaista&url=https://elisa.fi/kauppa/nettiliittymat/kiinteat-laajakaistat"
          name="Elisa laajakaista"
          operator="elisa"
        />
        <FixedInternetCard
          link="https://www.telia.fi/kauppa/kodin-netti/netti-kotiin"
          name="Telia laajakaista"
          operator="telia"
        />
        <FixedInternetCard
          link="https://track.adtraction.com/t/t?a=1187383862&as=1543031511&t=2&tk=1&epi=dna-kiintealaajakaista&url=https://kauppa4.dna.fi/laajakaista"
          name="DNA laajakaista"
          operator="dna"
        />
      </Box>
    </RightContainer>
  )
}
