import { Box, Text } from "@chakra-ui/core"
import React from "react"

type Props = {
  text: string
  bg: string
}

export const HighlighText: React.FC<Props> = ({ text, bg }) => {
  return (
    <Box
      bg={bg}
      textAlign="center"
      borderRadius="5px"
      width="70%"
      margin="-2rem auto"
    >
      <Text color="white" fontWeight="800">
        {text}
      </Text>
    </Box>
  )
}
