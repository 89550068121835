import { compact } from "@apollo/client/utilities"
import { Box, Text } from "@chakra-ui/core"
import React, { useState } from "react"
import { MobilePlan } from "../../../../types/MobilePlan"
import { FilterButton } from "./FilterButton"
import { MobilePlanCard } from "./MobilePlanCard"

type Props = {
  plans: MobilePlan[]
  filters?: boolean
  slug: string
  compact?: boolean
}

export const MobilePlanCardsContainer: React.FC<Props> = ({
  plans,
  filters,
  slug,
  compact,
}) => {
  const [mobilePlans, setMobilePlans] = useState(plans)
  const [currentFilter, setCurrentFilter] = useState("kaikki")
  const planCards = mobilePlans.map((p, i) => (
    <MobilePlanCard key={i} slug={slug} plan={p} compact={compact} />
  ))
  const showUnlimited = () => {
    setMobilePlans(plans.filter(p => p.unlimited))
    setCurrentFilter("rajattomat")
  }
  const showLimited = () => {
    setMobilePlans(plans.filter(p => !p.unlimited))
    setCurrentFilter("kaytonmukaan")
  }
  const showAll = () => {
    setCurrentFilter("kaikki")
    setMobilePlans(plans)
  }
  return (
    <Box>
      {filters && (
        <Box
          display="flex"
          flexDir={["column", "column", "row", "row"]}
          justifyContent="center"
          alignItems="center"
          marginY="3rem"
        >
          <Text>Näytetään</Text>
          <FilterButton
            name="Kaikki"
            active={currentFilter === "kaikki"}
            filterAction={() => showAll()}
          />
          <FilterButton
            name="Rajattomat"
            active={currentFilter === "rajattomat"}
            filterAction={() => showUnlimited()}
          />
          <FilterButton
            name="Käytön mukaan"
            active={currentFilter === "kaytonmukaan"}
            filterAction={() => showLimited()}
          />
        </Box>
      )}
      <Text textAlign="center">
        Tutustu alapuolelta {mobilePlans.length} eri liittymään
      </Text>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {planCards}
      </Box>
    </Box>
  )
}
