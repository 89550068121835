import { Box, Button } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = { firstButton: string; secondButton: string }

export const DealButtons: React.FC<Props> = ({ firstButton, secondButton }) => {
  return (
    <Box marginTop="3rem" flex="1">
      <Link to="#favorites">
        <Button
          color="white"
          bg="action"
          borderRadius="20px"
          fontWeight="300"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="1rem"
          cursor="pointer"
        >
          {firstButton}
        </Button>
      </Link>
      <Link to="#result">
        <Button
          cursor="pointer"
          color="action"
          border="1px solid action"
          borderRadius="20px"
          fontWeight="300"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          marginY="1rem"
          margin="1rem"
          outline="none"
          borderStyle="solid"
          borderColor="action"
        >
          {secondButton}
        </Button>
      </Link>
    </Box>
  )
}
