import { PseudoBox } from "@chakra-ui/core"
import React from "react"

type Props = { active?: boolean; name: string; filterAction: () => void }

export const FilterButton: React.FC<Props> = ({
  active,
  name,
  filterAction,
}) => {
  return (
    <PseudoBox
      onClick={filterAction}
      bg={active ? "#34268C" : "#ECECEC"}
      color={active ? "white" : "#34268C"}
      padding="0.5rem 2rem"
      margin="1rem"
      fontWeight="600"
      cursor="pointer"
      _hover={{
        bg: active ? "#34268C" : "#CDCCCE",
        transition: "background-color 0.5s ease",
      }}
    >
      {name}
    </PseudoBox>
  )
}
