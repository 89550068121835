import { Box, Button, Text } from "@chakra-ui/core"
import React from "react"
import { CardContainer } from "../../cards/CardContainer"
import OperatorImage from "../../images/OperatorImage"

type Props = { name: string; operator: string; price: string; link: string }

export const PrepaidCard: React.FC<Props> = ({
  name,
  operator,
  price,
  link,
}) => {
  return (
    <CardContainer name={name} slug="prepaid" directLink={link}>
      <Text fontWeight="600">{name}</Text>
      <Box width="200px" marginY="1rem">
        <OperatorImage name={operator} />
      </Box>
      <Box marginY="1rem">
        <Text fontSize="2.2rem" fontWeight="800">
          <Text as="span" fontSize="1rem" fontWeight="300">
            alkaen
          </Text>
          {price}€
        </Text>
      </Box>
      <Box>
        <Button
          border="none"
          bg="action"
          color="white"
          fontSize="1.3rem"
          width="220px"
          height="50px"
          cursor="pointer"
        >
          Tutustu ja tilaa
        </Button>
        <Box fontWeight="400" fontSize="1.1rem">
          Ei määräaikaa
        </Box>
      </Box>
    </CardContainer>
  )
}
