import { Box } from "@chakra-ui/core"
import { useStaticQuery } from "gatsby"
import React from "react"
import { MobilePlan } from "../../types/MobilePlan"
import { MobilePlanCard } from "../cards/productCads/mobilePlanCard/MobilePlanCard"
import { Heading3 } from "../headings/Heading3"
import { RightContainer } from "./RightContainer"

type Props = {
  plans: MobilePlan[]
  title: string
  slug: string
  compact?: boolean
}

export const DealContainer: React.FC<Props> = ({
  slug,
  plans,
  title,
  compact,
}) => {
  const newPlans = plans.slice(0, 3)
  const planCards = newPlans.map(plan => (
    <MobilePlanCard
      slug={slug}
      plan={plan}
      compact={false}
      showBenefits={false}
    />
  ))

  return (
    <RightContainer>
      <Heading3>{title}</Heading3>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        marginTop="3rem"
      >
        {planCards}
      </Box>
    </RightContainer>
  )
}
