import { Box } from "@chakra-ui/core"
import React from "react"
import { Heading3 } from "../../headings/Heading3"
import { RightContainer } from "../RightContainer"
import { PrepaidCard } from "./PrepaidCard"

export const PrepaidContainer = () => {
  return (
    <RightContainer>
      <Heading3>Meidän suositukset prepaid liittymäksi</Heading3>
      <Box display="flex" flexWrap="wrap">
        <PrepaidCard
          link="https://track.adtraction.com/t/t?a=1187383862&as=1543031511&t=2&tk=1&epi=dna-prepaid&url=https://www.dna.fi/prepaid-liittymat"
          name="DNA prepaid"
          operator="dna"
          price="4,90"
        />
        <PrepaidCard
          link="https://track.adtraction.com/t/t?a=1298137859&as=1543031511&t=2&tk=1&epi=elisa-prepaid&url=https://elisa.fi/prepaid/prepaid-paketit/"
          name="Elisa prepaid"
          operator="elisa"
          price="6,90"
        />
        <PrepaidCard
          link="https://www.telia.fi/kauppa/liittymat/prepaid"
          name="Telia prepaid"
          operator="telia"
          price="4,90"
        />
      </Box>
    </RightContainer>
  )
}
