import React from "react"
import { Box, PseudoBox } from "@chakra-ui/core"
import Img from "gatsby-image"
import { AffiliateLink } from "../buttons/AffiliateLink"

type Props = {
  name: string
  slug: string
  compact?: boolean
  directLink?: string
}

export const CardContainer: React.FC<Props> = ({
  name,
  slug,
  children,
  compact,
  directLink,
}) => {
  return (
    <AffiliateLink name={name} slug={slug} directLink={directLink}>
      <Box
        border="1px solid #d7d1f8"
        borderRadius="5px"
        margin={!compact ? "0.1rem" : ["0.1rem", "1rem"]}
        marginY="2rem"
        textAlign="center"
        bg="white"
        display="flex"
        padding={!compact ? "0.1rem" : ["0.1rem", "1rem"]}
        flexDir="column"
        alignItems="center"
        width={!compact ? ["300px", "250px"] : ["300px", "400px"]}
      >
        {children}
      </Box>
    </AffiliateLink>
  )
}
