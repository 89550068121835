import { compact } from "@apollo/client/utilities"
import { Box, List, ListItem, ListIcon, Button, Text } from "@chakra-ui/core"
import { PageProps } from "gatsby"
import React from "react"
import { MobileInternetPlan } from "../../../types/MobileInternetPlan"
import { CardContainer } from "../../cards/CardContainer"
import { HighlighText } from "../../cards/productCads/cardItems/HighlighText"
import { PlanItem } from "../../cards/productCads/cardItems/PlanItem"
import OperatorImage from "../../images/OperatorImage"

type Props = {
  plan: MobileInternetPlan
  compact: boolean | undefined
  slug: string
}

export const MobileInternetCard: React.FC<Props> = ({
  plan,
  compact,
  slug,
}) => {
  return (
    <CardContainer name={plan.name} slug={slug} compact={compact}>
      <HighlighText bg={"info"} text={`${plan.internetType}`} />
      <Text marginTop="3rem" fontSize="1.1rem" textTransform="capitalize">
        {plan.operator}
      </Text>
      <Box width="200px" m="1rem auto">
        <OperatorImage name={plan.operator} />
      </Box>
      <Text fontWeight="600" fontSize="1.2rem">
        {plan.name}
      </Text>
      <PlanItem
        title="Internetin max nopeus"
        value={`${plan.internetSpeed} Mbit/s`}
      />
      <PlanItem title="Netti EU-maissa" value={`${plan.roaming} Gt/kk`} />
      <Box>
        {compact && (
          <List
            spacing={3}
            textAlign="left"
            fontWeight="600"
            fontSize="0.9rem"
            marginX="1rem"
            textTransform="capitalize"
            minH="150px"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginTop="2rem"
          >
            {plan.benefits.map(b => (
              <ListItem color="#34268C">
                <ListIcon icon="check-circle" color="#34268C" />
                {b}
              </ListItem>
            ))}
          </List>
        )}
        <Text fontSize="2.2rem" fontWeight="800">
          {plan.afterSalePrice ? (
            <Text
              marginY="0.1rem"
              paddingY="0.1rem"
              as="span"
              fontSize="0.8rem"
              color="info"
              marginX="0.5rem"
            >
              12 kk ajan
            </Text>
          ) : (
            ""
          )}
          {plan.price}€
          <Text
            marginY="0.1rem"
            paddingY="0.1rem"
            as="span"
            fontSize="1rem"
            fontWeight="300"
          >
            /kk
          </Text>
          {plan.dailyFee ? (
            <Text fontWeight="600" fontSize="0.8rem" padding="0rem 2rem">
              Lisäksi max 0.99€/vrk, niiltä päiviltä kun käytät internettiä
            </Text>
          ) : (
            ""
          )}
          {plan.afterSalePrice ? (
            <>
              <Text fontSize="0.8rem" fontWeight="300">
                12kk jälkeen {plan.afterSalePrice}€.
              </Text>
              <Text fontSize="0.8rem" fontWeight="300">
                Ei määräaikaisuutta.
              </Text>
            </>
          ) : (
            <Text fontSize="0.8rem" fontWeight="300">
              Voit irtisanoa liittymän millon haluat!
            </Text>
          )}
        </Text>
      </Box>

      <Button
        border="none"
        bg="action"
        color="white"
        fontSize="1.3rem"
        width="220px"
        height="50px"
        cursor="pointer"
      >
        Tutustu ja tilaa
      </Button>
      <Box fontWeight="700" marginTop="1rem">
        Avausmaksu {plan.openFee}€
      </Box>
      <Box fontWeight="400" fontSize="1.1rem">
        Ei määräaikaa
      </Box>
    </CardContainer>
  )
}
