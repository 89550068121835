import { Box } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const RightContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box paddingTop={["10px", "10px", "10px", "10px", "200px"]}>{children}</Box>
  )
}
