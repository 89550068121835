import { Box, Button, Heading } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = {}

export const MobileInternetButtons: React.FC<Props> = ({}) => {
  return (
    <Box marginTop="3rem" flex="1">
      <Link to="#favorites">
        <Button
          cursor="pointer"
          color="action"
          border="1px solid action"
          borderRadius="20px"
          fontWeight="300"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          margin="0.4rem"
          outline="none"
          borderStyle="solid"
          borderColor="action"
        >
          Katso suosikkimme
        </Button>
      </Link>
      <Link to="#result">
        <Button
          cursor="pointer"
          color="action"
          border="1px solid action"
          borderRadius="20px"
          fontWeight="300"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          margin="0.4rem"
          outline="none"
          borderStyle="solid"
          borderColor="action"
        >
          Kaikki mobiililaajakaistat
        </Button>
      </Link>
      <Heading as="h4" fontSize="1.5rem" margin="0.5rem 1rem" marginTop="2rem">
        Etsitkö jotain muuta?
      </Heading>
      <Link to="/laajakaista-vertailu">
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso kiinteät laajakaistat
        </Button>
      </Link>
      <Link to="/mokkula">
        <Button
          color="white"
          bg="info"
          borderRadius="20px"
          fontWeight="200"
          minWidth="300px"
          maxW="90vw"
          height="50px"
          fontSize="1.3rem"
          border="none"
          margin="0.4rem"
          cursor="pointer"
        >
          Katso mokkulat
        </Button>
      </Link>
    </Box>
  )
}
