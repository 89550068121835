import React from "react"
import { Button, Link, PseudoBox } from "@chakra-ui/core"
import { ButtonSizes, ButtonColors } from "../../types/Button/Button"
import { trackClick } from "../../utils/tracking/trackingHelp"
import { useMetaData } from "../../hooks/useMetaData"

type Props = {
  bg?: ButtonColors
  name: string
  size?: ButtonSizes
  button?: boolean
  slug: string
  directLink?: string
}

const prettyName = (name: string) => {
  return name.replace(/(\W+)/g, "").toLowerCase()
}

export const AffiliateLink: React.FC<Props> = ({
  bg,
  name,
  size,
  button,
  children,
  slug,
  directLink,
}) => {
  const metaData = useMetaData()
  return (
    <Link
      isExternal
      href={
        directLink
          ? directLink
          : `/${metaData.urlRedirectFix}/${prettyName(slug)}-${prettyName(
              name
            )}`
      }
      textDecoration="none"
      m="unset"
      color="unset"
      p="unset"
      outline="none"
      textTransform="none"
      _hover={{ textDecoration: "none" }}
      onClick={() => trackClick("affiKlikki", name, slug)}
    >
      {button && (
        <Button
          cursor="pointer"
          bg={bg}
          size={size}
          color="white"
          variant="outline"
          m="1rem"
          borderRadius="1rem"
          _hover={{
            outline: "none",
            bg: `${bg}Hover`,
            boxShadow: "outline",
            transition: "all 1s",
            borderColor: "gray.300",
          }}
        >
          {children}
        </Button>
      )}
      {!button && (
        <PseudoBox m="unset" p="unset" textDecoration="none" outline="none">
          {children}
        </PseudoBox>
      )}
    </Link>
  )
}
