import { Box, Text } from "@chakra-ui/core"
import React from "react"

type Props = {
  title: string
  value: string
}

export const PlanItem: React.FC<Props> = ({ title, value }) => {
  return (
    <Box
      display="flex"
      bg="#F4F2FF"
      justifyContent="space-between"
      marginX="0.7rem"
      padding="0.1rem"
      marginY="0.3rem"
      width="90%"
    >
      <Text
        padding="0rem 0.2rem"
        margin="0rem"
        fontWeight="600"
        textAlign="left"
        fontSize="0.9rem"
      >
        {title}
      </Text>
      <Text
        padding="0rem 0.2rem"
        margin="0rem"
        fontWeight="400"
        textAlign="right"
      >
        {value}
      </Text>
    </Box>
  )
}
