import { compact } from "@apollo/client/utilities"
import { Box, List, ListItem, ListIcon, Button, Text } from "@chakra-ui/core"
import React from "react"
import { HuntingPlan } from "../../../types/HuntingPlan"
import { CardContainer } from "../../cards/CardContainer"
import { HighlighText } from "../../cards/productCads/cardItems/HighlighText"
import { PlanItem } from "../../cards/productCads/cardItems/PlanItem"
import OperatorImage from "../../images/OperatorImage"

type Props = {
  plan: HuntingPlan
  slug: string
}

export const HuntingCard: React.FC<Props> = ({ plan, slug }) => {
  return (
    <CardContainer name={plan.name} slug={slug}>
      <Box flex="1">
        <HighlighText bg={"info"} text={plan.name} />
        <Text
          marginTop="3rem"
          fontSize="1.1rem"
          textTransform="capitalize"
        ></Text>
      </Box>
      <Box width="200px" m="1rem auto" flex="1">
        <OperatorImage name={"elisa"} />
      </Box>
      <Box flex="6">
        <PlanItem title="Latausnopeus" value={`${plan.downloadSpeed} Mbit/s`} />
        <PlanItem title="Lähetysnopeus" value={`${plan.uploadSpeed} Mbit/s`} />
        <PlanItem title="EU dataa" value={`${plan.roaming} Gt/kk`} />
      </Box>
      <Box flex="6">
        <Text fontSize="2.2rem" fontWeight="800">
          {plan.price}€
          <Text as="span" fontSize="1rem" fontWeight="300">
            /kk
          </Text>
          <Text fontSize="0.8rem" fontWeight="300">
            Voit irtisanoa liittymän millon haluat!
          </Text>
        </Text>
      </Box>
      <Box flex="9">
        <Button
          border="none"
          bg="action"
          color="white"
          fontSize="1.3rem"
          width="220px"
          height="50px"
          cursor="pointer"
        >
          Tutustu ja tilaa
        </Button>
        <Box fontWeight="700" marginTop="1rem">
          Avausmaksu {plan.openFee}€
        </Box>
        <Box fontWeight="400" fontSize="1.1rem">
          Ei määräaikaa
        </Box>
      </Box>

      <Box flex="22">
        <List
          spacing={3}
          textAlign="left"
          fontWeight="600"
          fontSize="0.9rem"
          marginX="1rem"
          textTransform="capitalize"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginTop="2rem"
        >
          {plan.alarm && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              Hälytyslaitteisiin
            </ListItem>
          )}
          {plan.heating && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              Lämmityslaitteisiin
            </ListItem>
          )}
          {plan.gps && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              GPS-laitteisiin
            </ListItem>
          )}
          {plan.huntingImage && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              Valvonta- ja ristakameraan kuvalla
            </ListItem>
          )}
          {plan.huntingVideo && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              Valvonta- ja ristakameraan videolla
            </ListItem>
          )}
          {plan.huntingLive && (
            <ListItem marginY="0.1rem" color="#34268C">
              <ListIcon icon="check-circle" color="#34268C" />
              Valvonta- ja ristakameraan live-videolla
            </ListItem>
          )}
        </List>
      </Box>
    </CardContainer>
  )
}
