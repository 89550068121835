import React from "react"
import { Box, Heading, Button } from "@chakra-ui/core"
import { Layout } from "../../components/layout/Layout"
import { graphql, Link } from "gatsby"
import rehypeReact from "rehype-react"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { SidebarRight } from "../../components/containers/sideBars/SidebarRight"
import { HeaderWithColor } from "../../components/headers/HeaderWithColor"
import { Seo } from "../../components/seo/Seo"
import { MainContainer } from "../../components/containers/mainContainer/MainContainer"
import { FixedSidebar } from "../../components/containers/sideBars/fixedSidebar/FixedSidebar"
import { Heading1 } from "../../components/headings/Heading1"
import { HeaderLeft } from "../../components/headers/headerContainers/HeaderLeft"
import { HeaderRight } from "../../components/headers/headerContainers/HeaderRight"
import { Operators } from "../../components/operators/Operators"
import { MobilePlanCardsContainer } from "../../components/cards/productCads/mobilePlanCard/MobilePlanCardsContainer"
import { SloganText } from "../../components/texts/SloganText"
import { DescText } from "../../components/texts/DescText"
import { DealContainer } from "../../components/deals/DealContainer"
import { MobilePlan } from "../../types/MobilePlan"
import { HuntingDealsContainer } from "../../components/deals/hunting/HuntingDealsContainer"
import { PrepaidContainer } from "../../components/deals/prepaid/PrepaidContainer"
import { FixedInternetContainer } from "../../components/deals/fixedInternet/FixedInternetContainer"
import { MobileInternetContainer } from "../../components/deals/mobileInternet/MobileInternetContainer"
import { DealButtons } from "../../components/deals/DealButtons"
import { MobileInternetButtons } from "../../components/deals/mobileInternet/MobileInternetButtons"
import { FixedInternetButtons } from "../../components/deals/fixedInternet/FixexInternetButton"
import { MokkulaButtons } from "../../components/deals/mokkula/MokkulaButtons"
import { RelatedLinks } from "../../components/navigation/related/RelatedLinks"

type Props = {
  data: any
  pageContext: any
}
// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ data, pageContext }) => {
  const {
    title,
    metaTitle,
    metaDesc,
    sidebar,
    slug,
    subType,
    name,
    productFilter,
    compareTitle,
    parent,
    desc,
    slogan,
    firstButton,
    secondButton,
    productType,
  } = data.markdownRemark.frontmatter

  const filteredPlans =
    subType === "deal" &&
    pageContext.mobilePlans.filter((plan: MobilePlan) => {
      if (productType === "operator") return plan.operator === productFilter
      if (productType === "internetType")
        return plan.internetType === productFilter
      if (productType === "specialFilter")
        return plan.specialFilter === productFilter
      return plan
    })

  // Schema tiedot
  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })

  return (
    <Layout>
      <Seo title={metaTitle} desc={metaDesc} />
      <HeaderWithColor>
        <Box
          display="flex"
          alignItems="start"
          paddingTop="3rem"
          flexDir={["column", "column", "column", "column", "row"]}
        >
          <HeaderLeft>
            <Box flex="1" marginX={["1rem", "2rem", "2rem", "2rem", "0rem"]}>
              <SloganText text={slogan} />
              <Heading1>{title}</Heading1>
              <DescText>{desc}</DescText>
            </Box>
            {subType === "deal" && (
              <DealButtons
                firstButton={firstButton}
                secondButton={secondButton}
              />
            )}
            {subType === "fixedInternet" && <FixedInternetButtons />}
            {subType === "mobileInternet" && <MobileInternetButtons />}
            {subType === "mokkula" && <MokkulaButtons />}
            <Operators />
          </HeaderLeft>
          <HeaderRight>
            <a id="favorites">
              {subType === "deal" && (
                <DealContainer
                  slug={slug}
                  plans={filteredPlans}
                  title={compareTitle}
                />
              )}
              {subType === "hunting" && (
                <HuntingDealsContainer
                  slug={slug}
                  plans={pageContext.huntingPlans}
                />
              )}
              {subType === "prepaid" && <PrepaidContainer />}
              {subType === "fixedInternet" && <FixedInternetContainer />}
              {subType === "mobileInternet" && (
                <MobileInternetContainer
                  slug={slug}
                  limited={true}
                  plans={pageContext.mobileInternetPlans}
                  title={compareTitle}
                />
              )}
              {subType === "mokkula" && (
                <>
                  <MobileInternetContainer
                    slug={slug}
                    limited={true}
                    plans={pageContext.mobileInternetPlans}
                    title={compareTitle}
                  />
                  <Box display="flex" justifyContent="center">
                    <Link to="/mobiililaajakaista/#result">
                      <Button
                        color="white"
                        bg="info"
                        borderRadius="20px"
                        fontWeight="200"
                        minWidth="300px"
                        maxW="90vw"
                        height="50px"
                        fontSize="1.3rem"
                        border="none"
                        margin="0.4rem auto"
                        cursor="pointer"
                      >
                        Katso kaikki mobiililaajakaistat
                      </Button>
                    </Link>
                  </Box>
                </>
              )}
            </a>
          </HeaderRight>
        </Box>
      </HeaderWithColor>
      {subType === "deal" && (
        <Box>
          <Heading as="h4" size="md" textAlign="center" marginTop="6rem">
            Tutustu tästä kaikkiin tämän hetken tarjouksiin
          </Heading>
          <a id="result"></a>
          <MobilePlanCardsContainer
            compact={true}
            slug={slug}
            plans={filteredPlans}
            filters={true}
          />
        </Box>
      )}
      {subType === "mobileInternet" && (
        <Box>
          <Heading as="h4" size="md" textAlign="center" marginTop="6rem">
            Tutustu tästä kaikkiin tämän hetken tarjouksiin
          </Heading>
          <a id="result"></a>
          <MobileInternetContainer
            slug={slug}
            limited={false}
            plans={pageContext.mobileInternetPlans}
            compact={true}
          />
        </Box>
      )}
      <RelatedLinks parent={parent} pageName={name} />

      <MainContainer>
        <Box flex="8">
          <TextContainer>
            {renderAst(data.markdownRemark.htmlAst)}
          </TextContainer>
        </Box>
        {sidebar && (
          <SidebarRight>
            <FixedSidebar>
              <Heading as="h3">Sivupalkki</Heading>
            </FixedSidebar>
          </SidebarRight>
        )}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        sidebar
        slug
        slogan
        desc
        compareTitle
        firstButton
        secondButton
        subType
        metaTitle
        productType
        metaDesc
        name
        productFilter
        parent
      }
    }
  }
`

export default Template
