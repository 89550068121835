import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = { color?: string }

export const Heading3: React.FC<Props> = ({ children, color }) => {
  return (
    <Heading
      as="h3"
      textAlign="center"
      fontSize={["1.3rem", "1.4rem", "1.5rem", "1.7rem"]}
      paddingX={["1rem", "3rem"]}
      fontWeight="bold"
      size="lg"
      letterSpacing="0.3rem"
      color={color ? color : "white"}
    >
      {children}
    </Heading>
  )
}
