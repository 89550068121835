import { Box } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const HeaderRight: React.FC<Props> = ({ children }) => {
  return (
    <Box flex="2" width="100%">
      <Box
        background="linear-gradient(180deg, #6B53FD 0%, #34268C 100%)"
        borderRadius="5px"
        paddingX={["1rem", "1rem", "6rem", "6rem", "6rem"]}
        paddingBottom={["1rem", "1rem", "1rem", "1rem", "15rem"]}
        paddingTop={["0rem", "0rem", "0rem", "0rem", "-10rem"]}
        position="relative"
        boxSizing="border-box"
        marginTop={["100px", "100px", "100px", "100px", "-200px"]}
        borderBottomLeftRadius={["0px", "0px", "0px", "0px", "900px"]}
        borderTopLeftRadius={["0px", "0px", "0px", "0px", "500px"]}
      >
        {children}
      </Box>
      <Box
        minHeight="200px"
        width="300px"
        background="linear-gradient(180deg, #6B53FD 0%, #34268C 100%)"
        display={["none", "none", "none", "none", "block"]}
        position="absolute"
        borderBottomLeftRadius="9000px"
        borderTopLeftRadius="5000px"
        marginTop={["0px", "0px", "0px", "0px", "-110px"]}
        marginLeft={["0px", "0px", "0px", "0px", "-100px"]}
        right="0px"
      ></Box>
    </Box>
  )
}
